<template>
  <div class="phone-number-container align-items-center">
    <div class="d-flex w-100">
      <div class="w-100 mr-2">
        <BaseInput
          v-model="phoneInput"
          :countryCode="user.country.calling_code"
          :disabled="!editable"
          :errorMessage="phoneNumberError"
          :isInvalid="editable && $v.phoneInput.$error"
          :isPhoneInput="true"
          :label="$t('transaction.phone_number')"
          :placeholder="$t('transaction.phone_number_placeholder')"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </div>
      <div v-if="editable" class="confirm-phone">
        <button
          :disabled="$v.$invalid || loading"
          class="be-btn secondary blue circle-btn lg"
          role="button"
          type="button"
          @click.prevent="changePhoneNumber"
        >
          <i class="icon-success" />
        </button>
      </div>
      <a
        v-if="is_confirm"
        class="text-success"
        href="#"
        @click.prevent="changePhoneNumber"
      >
        <em v-if="counter > 0">
          {{ $t("utils.new_code_in") }} {{ counter }} s</em
        >
        <em v-else>{{ $t("utils.send_code") }}</em>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "UserPhoneNumber",
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      is_confirm: false,
      phoneInput: null,
      signature: null,
      code: null,
      counter: 180,
      error: null,
      codeInterval: null,
    };
  },

  created() {
    this.phoneInput = this.user.phone;
    this.$v.$touch();
  },

  computed: {
    ...mapState("auth", ["user"]),
    btnLabel() {
      return this.is_confirm
        ? this.$t("common.send")
        : this.$t("common.confirm");
    },
    phoneNumberError() {
      if (this.$v.phoneInput.$error || this.error) {
        if (this.error) {
          return this.error;
        }
        if (!this.$v.phoneInput.required) {
          return this.$t("utils.required.number");
        }
        if (!this.$v.phoneInput.minLength || !this.$v.phoneInput.maxLength) {
          return (
            this.$t("utils.required.numbers_length_before") +
            ` ${this.user.country.phoneLength} ` +
            this.$t("utils.characters")
          );
        }
      }
      return "";
    },
  },

  methods: {
    ...mapActions("auth", ["sendPhoneConfirmationCode", "confirmPhoneNumber"]),

    changePhoneNumber() {
      this.loading = true;
      this.confirmPhoneNumber({
        users_id: this.user.id,
        is_web: true,
        phone: this.phoneInput,
      })
        .then(() => {
          clearInterval(this.codeInterval);
          this.is_confirm = false;
          this.loading = false;
          this.error = null;
        })
        .catch(err => {
          if (err.response.data.message) this.error = err.response.data.message;
          this.code = null;
          this.loading = false;
        });
    },

    // changePhoneNumber() {
    //   if (this.is_confirm) {
    //     if (this.code.length >= 4) {
    //       this.confirmPhoneNumber({
    //         code: this.code,
    //         users_id: this.user.id,
    //         signature: this.signature,
    //         is_web: true,
    //         phone: this.phoneInput
    //       })
    //         .then(() => {
    //           clearInterval(this.codeInterval);
    //           this.is_confirm = false;
    //           this.error = null;
    //         })
    //         .catch(err => {
    //           if (err.response.data.message)
    //             this.error = err.response.data.message;
    //           this.code = null;
    //         });
    //     }
    //   } else {
    //     if (!this.$v.phoneInput.$error) {
    //       const phone = this.user.country.calling_code + this.phoneInput;
    //       this.sendPhoneConfirmationCode({ phone, web: true })
    //         .then(({ signature }) => {
    //           this.signature = signature;
    //           this.error = null;
    //           this.is_confirm = true;
    //           this.codeInterval = setInterval(() => {
    //             if (this.counter > 1) this.counter--;
    //             else {
    //               clearInterval(this.codeInterval);
    //               this.counter = 0;
    //             }
    //           }, 1000);
    //         })
    //         .catch(err => {
    //           if (err.response.data.message)
    //             this.error = err.response.data.message;
    //         });
    //     }
    //   }
    // }
  },

  validations() {
    return {
      phoneInput: {
        required,
        minLength: minLength(this.user.country.phoneLength),
        maxLength: maxLength(this.user.country.phoneLength),
      },
    };
  },

  beforeDestroy() {
    clearInterval(this.codeInterval);
  },
};
</script>

<style scoped></style>
